import React from "react";
import "../../pages/pocetna/style.css";
import { Link } from "react-router-dom";

import karateVrtic from "../../assets/karate_vrtic.png";
import karateSkola from "../../assets/karate_skola.png";
import takmicarskiKarate from "../../assets/takmicarski_karate.png";

const ProgramiThree = () => {
  return (
    <div className="column-section mt-100">
      <h2 className="hero-section-heading  mb-85">PROGRAMI</h2>
      <div className="three-items-div">
        <div className="program-card">
          <img
            alt="Karate Klub Iskra Karate Vrtic"
            src={karateVrtic}
            className="program-card-img"
          />
          <div className="program-card-content">
            <h3 className="program-card-h">Karate vrtić</h3>
            <p className="program-card-p">
              Karate vrtić je namijenjen najmladjim našim sugradjanima – uzrasta
              4-6 godina. Plan i program treninga grupe...
            </p>
            <Link
              onClick={() => {
                window.scroll(0, 0);
              }}
              className="link"
              to={"/programi/karate-vrtic"}
            >
              <div className="program-card-btn">Pogledaj</div>
            </Link>
          </div>
        </div>

        <div className="program-card">
          <img
            alt="Karate Klub Iskra Karate Skola"
            src={karateSkola}
            className="program-card-img"
          />
          <div className="program-card-content">
            <h3 className="program-card-h">Karate škola</h3>
            <p className="program-card-p">
              U karate školi osim daljeg razvijanja motoričkih sposobnosti,
              poseban naglasak se stavlja na učenje osnova karatea...
            </p>
            <Link
              onClick={() => {
                window.scroll(0, 0);
              }}
              className="link"
              to={"/programi/karate-skola"}
            >
              <div className="program-card-btn">Pogledaj</div>
            </Link>
          </div>
        </div>

        <div className="program-card">
          <img
            alt="Karate Klub Iskra Takmicarski Karate WKF"
            src={takmicarskiKarate}
            className="program-card-img"
          />
          <div className="program-card-content">
            <h3 className="program-card-h">Takmičarski karate</h3>
            <p className="program-card-p">
              Takmičarski karate je uskladjen prema pravilnicima koji važe na
              nivou KSCG, odnosno WKF (World Karate Federation)...
            </p>
            <Link
              onClick={() => {
                window.scroll(0, 0);
              }}
              className="link"
              to={"/programi/takmicarski-karate"}
            >
              <div className="program-card-btn">Pogledaj</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramiThree;
