import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import "./style.css";
import { useParams } from "react-router";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import Footer from "../../components/footer";
import { Helmet } from "react-helmet";

const Novost = () => {
  const { novostSlug } = useParams();
  const [loading, setLoading] = useState(false);
  const [novostData, setNovostData] = useState({});

  const formatDateTime = (dateString) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    return new Date(dateString)
      .toLocaleString("en-US", options)
      .replace(",", "");
  };

  useEffect(() => {
    const getNovost = async () => {
      try {
        setLoading(true);

        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/get-novost-by-slug`,
          { novostSlug }
        );
        setNovostData(response.data);

        await axios.post(`${process.env.REACT_APP_SERVER_URL}/add-view-count`, {
          novostSlug,
        });
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };

    getNovost();
  }, []);

  return (
    <div className="page-container">
      <Helmet>
        <title>{novostData.seoNaslov || "Default Title"}</title>
        <meta
          name="description"
          content={novostData.seoOpis || "Default Description"}
        />
        {novostData.image && (
          <meta property="og:image" content={novostData.image.url} />
        )}
      </Helmet>

      <Header />
      {loading ? (
        <div className="loading-div">
          <ClipLoader size={40} color={"#fff"} />
        </div>
      ) : (
        <div className="novost-content-div mt-100">
          <div className="novost-content">
            <img
              src={novostData?.image?.url}
              className="novost-content-img mt-32"
            />

            <div className="novost-content-heading-div">
              <h1 className="novost-content-heading text-align-center">
                {novostData?.naslov}
              </h1>

              <p className="novost-content-date-p">
                {formatDateTime(novostData?.timestamp)}
              </p>
            </div>

            <div
              className="novost-content-body"
              dangerouslySetInnerHTML={{ __html: novostData?.content }}
            ></div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default Novost;
