import React, { useState } from "react";
import "./style.css";
import Header from "../../components/header";
import heroImage from "../../assets/karate_vrtic_hero.png";
import karateVrtic1 from "../../assets/karate_vrtic_1.png";
import roditeljskiUgao from "../../assets/roditeljski_ugao.png";
import karateka from "../../assets/karateka.jpg";
import aktivnostiPlaceholder from "../../assets/aktivnosti_placeholder.png";
import Kontakt from "../../components/kontakt";
import Footer from "../../components/footer";

import questionSparkle from "../../assets/question_sparkle.png";
// preko moracani
import letter1 from "../../assets/letter_1.png";
import letter2 from "../../assets/letter_2.png";
import letter3 from "../../assets/letter_3.png";
import letter4 from "../../assets/letter_4.png";
import { Link } from "react-router-dom";

const KarateVrtic = () => {
  const [openQuestionIndex, setOpenQuestionIndex] = useState(-1);

  const qaData = [
    {
      id: 1,
      question: "Kada dijete treba uključiti u sport?",
      answer:
        "Brojni su savjeti, mnoge školice sporta počinju već od treće godine djeteta. Neko bi rekao da je to granica, bar ona tehničke prirode. Ipak, nije, jer pravila nema. Svakom djetetu treba pristupiti individualno, kao što on i jeste, pa ni svaki trogodišnjak ili trogodišnjakinja nijesu isti. Roditelj najbolje poznaje svoje dijete pa sam može procijeniti motivaciju i interesovanje djeteta za sport. Univerzalnog recepta nema jer ako se procijeni da ima, upadamo u zamku uporedjivanja mališana što svakako nije dobro po njihovo samopouzdanje i učinak.",
    },
    {
      id: 2,
      question: "Postoje li programi za trogodišnjake?",
      answer:
        "Postoje, ali nijesu svi kreirani skaldu sa razvojnim uzrastom djeteta. Tačnije, samo neki od njih su na pravi način prilagodjeni razvojnoj normi, ali i potencijalnim, zdravim odstupanjima svakog djeteta individualno.",
    },
    {
      id: 3,
      question: "Da li treba poštovati djetetovu želju?",
      answer:
        "Naravno. Pružajući djetetu mogućnost da bira u koliko god stvari je to moguće (naravno, nećete mu dati da zimi izadje u kupaćem kostimu), jačamo djetetovo samopouzdanje i samim tim motivaciju za uspjehom u svemu čime se bavi! ",
    },
    {
      id: 4,
      question:
        "Da li treba forsirati dijete na neki sport, iako on želi na drugi sport?",
      answer:
        "Treba mu pružiti mogućnost da vidi i jedno i drugo. Bilo kakvo forsiranje na manjem uzrastu može samo biti kontraproduktivno. Bitno je ipak naglasiti da nekoj djeci sport 'ne odgovara', jer predstavlja stvaranje radnih navika i discipline. Trener tada može biti prvi značajniji autoritet van primarne porodice, jer u porodici dijete nekad bude prezasićeno, pa se otpor sportu može javiti i iz tog razloga. Ovo je bitno prepoznati kako se ne bi otišlo u pogrešnom smjeru.",
    },
  ];

  const toggleQuestion = (index) => {
    setOpenQuestionIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  return (
    <div className="page-container">
      <Header />

      <div className="hero-section mb-32">
        <div className="hero-section-right-div animate__animated animate__fadeInLeft">
          <h1 className="hero-section-heading">KARATE VRTIC</h1>
          <p className="hero-paragraph">
            Karate vrtić je namijenjen najmladjim našim sugradjanima – uzrasta
            4-6 godina. Plan i program treninga grupe karate vrtića u potpunosti
            je prilagođen godištu i uzrastu polaznika. Naglasak je na višestrani
            razvoj motoričkih i funkcionalnih sposobnosti sa vježbama koje
            obuhvataju trčanje, skakanje, hvatanje, ravnoteža, kotrljanje itd.
          </p>
          <Link
            className="link"
            to={"/kontakt"}
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            <div className="button">Kontakt</div>
          </Link>
        </div>

        <div className="hero-section-left-div animate__animated animate__fadeInRight">
          <img
            alt="Karate Klub Iskra Karate skola"
            className="hero-img"
            src={heroImage}
          />
        </div>
      </div>

      <div className="two-things-div">
        <div className="one-side-thing-div">
          <img
            src={karateVrtic1}
            alt="Karate Klub Iskra Karate Vrtic"
            className="one-side-img"
          />
        </div>

        <div className="one-side-thing-div">
          <p className="hero-paragraph">
            Ovi treninzi prilagođeni su uzrastu djece te će im omogućiti razvoj
            motoričkih funkcija kroz igru i zabavu. Osim toga sportska
            rekreacija je vrlo bitna za očuvanje i razvoj zdravog načina života.
            Treninzi se održavaju u ugodnoj i opuštenoj atmosferi prilagođenoj
            uzrastu te uz odgovarajuće rekvizite i u sigurnoj okolini. Osnovni
            zadatak karate vrtića jeste da djeca zavole sport. Sve ostalo,
            predstavlja posebnu benefit.
          </p>
        </div>
      </div>

      <h2 className="hero-section-heading mb-32">RODITELJSKI UGAO</h2>

      <div className="two-things-div">
        <div className="one-side-thing-div">
          <p className="hero-paragraph">
            Svje je više sportova, klubova, informacija u našoj zemlji.
            Roditelji i djeca se nalaze dosta često u dilemi na koju stranu
            krenuti. Koji sport odabrati? Nekad djeca prilikom izbora odluče da
            zbog druga iz vrtića upišu neki sport, a nekad zbog roditelja. Da li
            smo donjeli ispravnu odluku? Da li smo pravilno usmjerili dijete?
          </p>
          <p className="hero-paragraph">
            Kako bismo roditeljima i djeci pomogli u izboru sporta, obavili smo
            razgovor sa Marijom Babić, psihologom i psihoterapeuom, koja nam je
            odgovorila na najčešća pitanja roditelja i objasnila nam zašto, kada
            i kako dijete treba uvesti u svijest sporta.
          </p>
        </div>

        <div className="one-side-thing-div">
          <img
            src={roditeljskiUgao}
            alt="Karate Roditeljski Ugao Psiha"
            className="one-side-img"
          />
        </div>
      </div>

      <div className="questions-div">
        {qaData.map((data) => {
          return (
            <div
              key={data.id}
              className={`question-tab ${
                openQuestionIndex === data.id ? "question-tab-active" : ""
              }`}
            >
              <div className="question-content">
                <h3 className="question-h">{data.question}</h3>

                <img
                  onClick={() => {
                    toggleQuestion(data.id);
                  }}
                  className={`question-sparkle ${
                    openQuestionIndex === data.id
                      ? "question-sparkle-active"
                      : ""
                  }`}
                  src={questionSparkle}
                />
              </div>

              <p className="answer-content">{data.answer}</p>
            </div>
          );
        })}
      </div>

      <h2 className="hero-section-heading mb-32">MALA KARATEKA ISRKA</h2>

      <div className="two-things-div">
        <div className="one-side-thing-div">
          <img
            src={karateka}
            alt="Karate Klub Iskra Istorijat"
            className="one-side-img"
          />
        </div>

        <div className="one-side-thing-div">
          <p className="hero-paragraph">
            U martu mjesecu 2022.godine uz finansijsku pomoć Slovačke Ambasade
            objavili smo knjigu za djecu pod nazivom “Mala karateka Iskra” koja
            je podijeljena besplatno u preko 1000 domova u Danilovgradu i
            Podgorici.
          </p>
          <p className="hero-paragraph">
            Knjiga je namijenjena djeci predškolskog i ranog školskog uzrasta,
            sa namjerom da im pomognemo da se, uz zajedničku igru i zabavu,
            djeca usmjere ka sportu. Sportu kao jednim od najboljh čuvara
            zdravlja i velika pomoć pri pravilnom razvoju tijela i psihologije
            mladog bića.
          </p>
          <p className="hero-paragraph">
            Potrudili smo se da na atraktivan i jednostavan način upoznamo
            mališane sa značenjem sporta. Ilutracije i fotografije, prilagodjene
            njihovom uzrastu, pokazalo im je na očigledan i jednostavan način
            raznorazne igre, koje se lako uče i saladavaju, pričinjavaju djeci
            zadovoljstvo, a u stvari su prvi stepenik nap utu koji se zove
            sport.
          </p>
        </div>
      </div>

      <h2 className="hero-section-heading mb-32">ZANIMLJIVOSTI</h2>

      <div className="zanimljivosti-div">
        <div className="zanimljivost-tab">
          <img className="japan-letter-img" src={letter1} />

          <div>
            <h3 className="hero-paragraph-subheading">Jeste li znali?</h3>
            <p className="hero-paragraph">
              Da karate trenira preko 100 miliona ljudi u 192 zemlje u svijetu.
            </p>
          </div>
        </div>
        <div className="zanimljivost-tab">
          <img className="japan-letter-img" src={letter2} />

          <div>
            <h3 className="hero-paragraph-subheading">Jeste li znali?</h3>
            <p className="hero-paragraph">
              Da se karate u Crnoj Gori trenira od 1968. godine. Trenutno ima
              registrovanih 35 klubova u Karate savezu Crne Gore, sa preko 1500
              aktivnih vježbača.
            </p>
          </div>
        </div>
        <div className="zanimljivost-tab">
          <img className="japan-letter-img" src={letter3} />

          <div>
            <h3 className="hero-paragraph-subheading">Jeste li znali?</h3>
            <p className="hero-paragraph">
              Da je UNESKO rekao da je karate sportska disciplina najbolji
              trening za djecu i mlade uzrasta od 4. do 21. godinu.
            </p>
          </div>
        </div>
        <div className="zanimljivost-tab">
          <img className="japan-letter-img" src={letter4} />

          <div>
            <h3 className="hero-paragraph-subheading">Jeste li znali?</h3>
            <p className="hero-paragraph">
              Da od svih sportista iz Crne Gore na evropskim i svjetskim
              prvenstvima najuspješniji su bili karatisti.
            </p>
          </div>
        </div>
      </div>

      <Kontakt />

      <Footer />
    </div>
  );
};

export default KarateVrtic;
