import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";

import footerInstagram from "../../assets/footer_instagram.png";
import footerFacebook from "../../assets/footer_facebook.png";
import iskraLogo from "../../assets/transparent_logo.png";
import visureLogo from "../../assets/visure_white.png";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-content">
        <div className="footer-links-div">
          <div className="footer-nav-links">
            <Link
              onClick={() => {
                window.scroll(0, 0);
              }}
              to={"/"}
              className="footer-nav-link"
            >
              Naslovna
            </Link>
            <Link
              onClick={() => {
                window.scroll(0, 0);
              }}
              to={"/o-klubu"}
              className="footer-nav-link"
            >
              O klubu
            </Link>
            <Link
              onClick={() => {
                window.scroll(0, 0);
              }}
              to={"/programi"}
              className="footer-nav-link"
            >
              Programi
            </Link>
            <Link
              onClick={() => {
                window.scroll(0, 0);
              }}
              to={"/rezultati"}
              className="footer-nav-link"
            >
              Rezultati
            </Link>
            <Link
              onClick={() => {
                window.scroll(0, 0);
              }}
              to={"/novosti"}
              className="footer-nav-link"
            >
              Novosti
            </Link>
            <Link
              onClick={() => {
                window.scroll(0, 0);
              }}
              to={"/kontakt"}
              className="footer-nav-link"
            >
              Kontakt
            </Link>
          </div>

          <div className="footer-social-links">
            <a href="https://www.instagram.com/karateklubiskra" target="_blank">
              <img
                className="footer-socail-img"
                alt="Karate Klub Iskra Facebook"
                src={footerInstagram}
              />
            </a>
            <a
              href="https://www.facebook.com/p/Karate-klub-ISKRA-100063772861426/"
              target="_blank"
            >
              <img
                className="footer-socail-img"
                alt="Karate Klub Iskra Instagram"
                src={footerFacebook}
              />
            </a>
          </div>
        </div>

        <div className="footer-line" />

        <div className="footer-logos-div">
          <div className="footer-iskra-div">
            <img
              src={iskraLogo}
              alt="Karate Klub Iskra Logo"
              className="footer-iskra-logo"
            />
            <p className="footer-iskra-p">Karate Klub Iskra</p>
          </div>

          <a target="_blank" href="https://visuredigital.com">
            <img
              className="footer-visure-logo"
              src={visureLogo}
              alt="Visure Digital Web Development"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
