import React, { useState } from "react";
import "../../pages/pocetna/style.css";
import emailjs from "emailjs-com";

import contactIcon from "../../assets/contact_icon.png";
import contactPhone from "../../assets/contact_phone.png";
import contactEmail from "../../assets/contact_email.png";
import contactLocation from "../../assets/contact_location.png";
import { toast, Toaster } from "react-hot-toast";
import ClipLoader from "react-spinners/ClipLoader";

const Kontakt = () => {
  const [ime, setIme] = useState("");
  const [email, setEmail] = useState("");
  const [poruka, setPoruka] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const errorMessages = {
    emptyField: "Sva polja su obavezna.",
  };

  const serviceId = "service_8ajblfh";
  const templateId = "template_57l5cz4";
  const publicKey = "rmNXYdD8gJaiiAAEY";

  emailjs.init(publicKey);

  const sendEmail = async () => {
    setLoading(true);
    setError("");
    if (ime === "" || email === "" || poruka === "") {
      // setError(errorMessages.emptyField);
      toast.error("Sva polja su obavezna.");
      setLoading(false);
      return;
    } else {
      const emailParams = {
        to_email: "nemanjadragas12@gmail.com",
        from_name: ime,
        from_email: email,
        message: poruka,
      };

      emailjs.send(serviceId, templateId, emailParams).then(
        function (response) {
          toast.success("Poruka uspješno poslata.");
          setIme("");
          setEmail("");
          setPoruka("");
          setLoading(false);
        },
        function (error) {
          toast.error("Došlo je do greške.");
          setLoading(false);
        }
      );
    }
  };

  return (
    <>
      <Toaster />
      <div className="column-section mt-100">
        <h2 className="hero-section-heading ">KONTAKT</h2>

        <div className="contact-div">
          <div className="contact-div-form-div">
            <div className="contact-div-form">
              <img src={contactIcon} className="contact-div-img" />
              <input
                value={ime}
                onChange={(e) => {
                  setIme(e.target.value);
                }}
                placeholder="Vaše ime"
                className="contact-input"
              />
              <input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="Vaš email"
                className="contact-input"
              />
              <textarea
                value={poruka}
                onChange={(e) => {
                  setPoruka(e.target.value);
                }}
                placeholder="Poruka"
                className="contact-textarea"
              />
              {error && <div className="error-message">{error}</div>}
              <div onClick={sendEmail} className="contact-form-btn">
                {loading ? <ClipLoader size={20} color={"#fff"} /> : "Pošalji"}
              </div>
            </div>
          </div>

          <div className="contact-div-line" />

          <div className="contact-info-div">
            <h4 className="contact-info-h">
              Kontaktirajte nas za više informacija
            </h4>

            <div className="contact-info-bar">
              <img src={contactPhone} className="contact-info-img" />
              <p className="contact-info-p">+382 67 518 080</p>
            </div>

            <div className="contact-info-bar">
              <img src={contactPhone} className="contact-info-img" />
              <p className="contact-info-p">+382 67 285 464</p>
            </div>

            <div className="contact-info-bar">
              <img src={contactEmail} className="contact-info-img" />
              <p className="contact-info-p">karateklubiskra@gmail.com</p>
            </div>

            <div className="contact-info-bar">
              <img src={contactLocation} className="contact-info-img" />
              <p className="contact-info-p">
                Danilovgrad, ul. Novice Škerovića BB
              </p>
            </div>

            <div className="contact-info-bar">
              <img src={contactLocation} className="contact-info-img" />
              <p className="contact-info-p">
                Podgorica, blok 5, ul. Velimira Terzića br.3
              </p>
            </div>

            <div className="contact-info-bar">
              <img src={contactLocation} className="contact-info-img" />
              <p className="contact-info-p">
                Podgorica, Zabjelo, ul. Radosava Burića br.7
              </p>
            </div>

            <div className="contact-info-bar">
              <img src={contactLocation} className="contact-info-img" />
              <p className="contact-info-p">
                Podgorica, Stari Aerodrom, OŠ “Pavle Rovinski”
              </p>
            </div>

            <div className="contact-info-bar">
              <img src={contactLocation} className="contact-info-img" />
              <p className="contact-info-p">
                Podgorica, Tološi, OŠ “Radojica Perović”
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Kontakt;
