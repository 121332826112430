import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Kontakt from "../../components/kontakt";

import heroImage from "../../assets/takmicarski_karate_hero.png";
import heroGroupImage from "../../assets/takmicarski_karate_column.png";
import { Link } from "react-router-dom";

const TakmicarskiKarate = () => {
  return (
    <div className="page-container">
      <Header />

      <div className="hero-section">
        <div className="hero-section-right-div animate__animated animate__fadeInLeft">
          <h1 className="hero-section-heading">TAKMICARSKI KARATE</h1>
          <p className="hero-paragraph">
            Takmičarski karate je uskladjen prema pravilnicima koji važe na
            nivou KSCG, odnosno WKF (World Karate Federation). Adekvatna
            priprema, postepeni rast i razvoj svakog takmičara, kako bi svoj
            maksimum svaki takmičar ostvario u starijim uzrasnim kategorijama
            (seniori). Adekvatan psiho-fizički razvoj je prioritetan cilj, dok
            sportski rezultat je dodatan benefit. Akcenat je da svaki naš
            takmičar bude produktivan član naše društvene zajednice.
          </p>
          <Link
            className="link"
            to={"/kontakt"}
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            <div className="button">Kontakt</div>
          </Link>
        </div>

        <div className="hero-section-left-div animate__animated animate__fadeInRight">
          <img
            alt="Karate Klub Iskra Hero image"
            className="hero-img"
            src={heroImage}
          />
        </div>
      </div>

      <div className="column-section animate__animated animate__fadeInUp">
        <img src={heroGroupImage} className="middle-hero-img" />
      </div>

      <Kontakt />

      <Footer />
    </div>
  );
};

export default TakmicarskiKarate;
