import React, { useEffect, useState } from "react";
import "./style.css";

import Header from "../../components/header";
import axios from "axios";
import heroImage from "../../assets/hero_img.png";
import heroGroupImage from "../../assets/hero-group-img.png";
import secondHeroImg from "../../assets/second_hero_img.png";
import blogArrow from "../../assets/blog_arrow.png";

import Footer from "../../components/footer";
import Kontakt from "../../components/kontakt";
import { Link } from "react-router-dom";
import ProgramiThree from "../../components/programi";

const Pocetna = () => {
  const [novosti, setNovosti] = useState([]);

  const formatDateTime = (dateString) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    return new Date(dateString)
      .toLocaleString("en-US", options)
      .replace(",", "");
  };

  useEffect(() => {
    const getNovosti = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/get-last-six`
        );

        setNovosti(response.data);
      } catch (err) {}
    };

    getNovosti();
  }, []);

  return (
    <div className="page-container">
      <Header />

      <div className="hero-section">
        <div className="hero-section-right-div animate__animated animate__fadeInLeft">
          <h1 className="hero-section-heading">
            DOBRODOSLI NA WEB STRANICU KARATE KLUB ISKRA
          </h1>
          <p className="hero-paragraph">
            Neka vas ova stranica provede kroz svijet karatea i približi vam
            našu strast prema ovom drevnom borilačkom sportu. Karate klub
            &quot;Iskra&quot; nije samo mjesto gdje treniramo, već je to
            zajednica posvećenih pojedinaca koji zajedno rastemo, učimo i
            nadmašujemo sebe. Naš klub nije samo o tehnikama i borbama; to je
            mjesto gdje gradimo karakter, poštovanje, samopouzdanje i
            istrajnost. Učimo od sebe i jedni od drugih, radeći zajedno kako
            bismo postigli svoje ciljeve. Ovdje ćete pronaći informacije o našim
            trenerima, rasporedu treninga, rezultatima naših takmičara i još
            mnogo toga.
          </p>
          <Link
            onClick={() => {
              window.scroll(0, 0);
            }}
            to={"/kontakt"}
            className="link"
          >
            <div className="button">Kontakt</div>
          </Link>
        </div>

        <div className="hero-section-left-div animate__animated animate__fadeInRight">
          <img
            alt="Karate Klub Iskra Hero image"
            className="hero-img"
            src={heroImage}
          />
        </div>
      </div>

      <div className="column-section animate__animated animate__fadeInUp">
        <h2 className="hero-section-heading ">PRIDRUZITE NAM SE!</h2>
        <p className="paragraph ">
          Bez obzira na to jeste li početnik, iskusna karateka ili jednostavno
          znatiželjni o karateu, pozivamo vas da istražite našu stranicu i možda
          čak dođete na trening i pridružite se našoj karate porodici. Karate
          nije samo sport, to je način života. Karate klub &quot;Iskra&quot; vas
          poziva da zajedno gradimo taj način života i stvaramo nezaboravne
          trenutke na tatamiju. Hvala vam što ste posjetili našu web stranicu, i
          nadamo se da ćemo vas uskoro vidjeti u našoj dvorani. Oss!
        </p>
        <img src={heroGroupImage} className="middle-hero-img" />
      </div>

      <div className="hero-section">
        <div className="second-hero-section-left-div ">
          <img
            alt="Karate Klub Iskra Second Hero image"
            className="second-hero-img"
            src={secondHeroImg}
          />
        </div>

        <div className="hero-section-right-div">
          {/* <h1 className="hero-section-heading">LOREWM IPSUM LOREM</h1> */}
          <p className="hero-paragraph">
            KK ISKRA sa ponosom okuplja šampione koji su osvojili balkanske,
            evropske i svjetske medalje. No, više od impresivnih sportskih
            uspjeha, mi se ističemo po tome što pružamo svakom djetetu priliku
            za optimalan rast i razvoj. KK &#39;Iskra&#39; se ne samo bavi
            izgradnjom sportskih karijera, već i oblikovanjem ličnosti.
          </p>
          <p className="hero-paragraph">
            Kao potvrda našeg posvećenog pristupa, klub je organizovan u čak 14
            selekcija, prilagođenih interesima, godinama i sposobnostima svakog
            člana. Nastojimo stvoriti okruženje u kojem svako dijete može
            pronaći svoje mjesto i postići svoj puni potencijal.
          </p>
          <p className="hero-paragraph">
            Mi smo više od kluba. Mi smo zajednica koja gradi budućnost, kako
            sportskih, tako i ljudskih šampiona.
          </p>
          <Link
            onClick={() => {
              window.scroll(0, 0);
            }}
            className="link"
            to={"/o-klubu"}
          >
            <div className="button">O klubu</div>
          </Link>
        </div>
      </div>

      <ProgramiThree />

      <div className="column-section">
        <h2 className="hero-section-heading ">NOVOSTI</h2>
        <div className="blogs-div">
          {novosti?.map((novost) => {
            return (
              <div key={novost.id} className="novost-card">
                <img
                  className="blog-card-img object-position-top"
                  src={novost.image.url}
                />
                <p className="blog-card-date">
                  {formatDateTime(novost?.timestamp)}
                </p>
                <h3 className="blog-card-heading">{novost?.seoNaslov}</h3>
                <p className="blog-card-text">{novost?.seoOpis}</p>
                <Link
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                  to={`/novosti/${novost.slug}`}
                >
                  <div className="blog-card-btn-div">
                    <p className="blog-card-btn-p">Pročitaj više</p>
                    <img className="blog-card-btn-img" src={blogArrow} />
                  </div>
                </Link>
              </div>
            );
          })}
        </div>

        <Link to={"/novosti"} className="button mt-48">
          Pogledaj još
        </Link>
      </div>

      <Kontakt />

      <Footer />
    </div>
  );
};

export default Pocetna;
