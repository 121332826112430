import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import ProgramiThree from "../../components/programi";

const Programi = () => {
  return (
    <div className="page-container">
      <Header />

      <ProgramiThree />

      <Footer />
    </div>
  );
};

export default Programi;
