import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Kontakt from "../../components/kontakt";

const KontaktPage = () => {
  return (
    <div className="page-container">
      <Header />
      <Kontakt />
      <Footer />
    </div>
  );
};

export default KontaktPage;
