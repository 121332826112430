import React from "react";
import Header from "../../components/header";
import oNamaHistory from "../../assets/o_nama_hero.png";
import oNamaSecond from "../../assets/o_nama_second.png";
import heroGroupImage from "../../assets/hero-group-img.png";
import klubPrijatelja from "../../assets/klub_prijatelja.png";
import vukZaric from "../../assets/vuk_zaric.png";
import nenadVojinovic from "../../assets/nenad_vojinovic.png";
import milosPopovic from "../../assets/milos_popovic.png";
import aleksandarBulatovic from "../../assets/aleksandar_bulatovic.jpg";
import markoDucic from "../../assets/marko_ducic.png";
import vladimirDavidovic from "../../assets/vladimir_davidovic.png";
import trenerPlaceholder from "../../assets/trener_placeholder.png";
import izleti from "../../assets/izleti.jpg";
import humanitarni from "../../assets/humaniratni_rad.jpeg";
import drustveno from "../../assets/drustveno_ponasanje.jpg";

import upravaZaro from "../../assets/uprava_zaro.png";
import upravaNesko from "../../assets/uprava_nesko.png";
import upravaPsiho from "../../assets/uprava_psiho.png";
import aleksandarIric from "../../assets/aleksandar_iric.jpg";
import upravaPlaceholder from "../../assets/uprava_placeholder.png";

import pozivnica from "../../assets/pozivnica.docx";

import "./style.css";
import Kontakt from "../../components/kontakt";
import Footer from "../../components/footer";

const OKlubu = () => {
  const downloadDocument = () => {
    const link = document.createElement("a");
    link.href = pozivnica;
    link.download = "pozivnica.docx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="page-container">
      <Header />

      <h2 className="hero-section-heading mt-100">O NAMA</h2>

      <div className="two-things-div">
        <div className="one-side-thing-div">
          <p className="hero-paragraph">
            U Septembru 2000. godine, na inicijativu Slobodana Žarića, pionira
            karate sporta, započeo je svoj put Karate klub &quot;Iskra&quot;.
            Slobodan Žarić je istaknuti član crnogorskog karate sporta, prvi
            šampion Crne Gore u teškoj kategoriji i prvi crnogorski takmičar
            koji je donio medalju u seniorskoj kategoriji na Prvenstvu SFR
            Jugoslavije. Slobodan je nasilac crnog pojasa 6.DAN. U dva mandata
            je bio i Predsjednik Karate saveza Crne Gore. Dr. Vladimir Jorga,
            počastan član i predsjednik osnivačke skupštine, dao je svoju
            podršku ovom početku.
          </p>
          <p className="hero-paragraph">
            Od 2000. godine pa sve do danas, klub je neprestano rastao, kako u
            pogledu članstva, tako i kada su u pitanju sportski rezultati.
            Nagrađivan je više puta od strane Opštine Danilovgrad za izvanredan
            doprinos razvoju sporta i mladih u ovoj regiji.
          </p>
        </div>

        <div className="one-side-thing-div">
          <img
            src={oNamaHistory}
            alt="Karate Klub Iskra Istorijat"
            className="one-side-img"
          />
        </div>
      </div>

      <div className="two-things-div">
        <div className="one-side-thing-div">
          <img
            src={oNamaSecond}
            alt="Karate Klub Iskra Istorijat"
            className="one-side-img"
          />
        </div>

        <div className="one-side-thing-div">
          <p className="hero-paragraph">
            Danas, Karate klub &quot;Iskra&quot; ima više od 30 reprezentativaca
            Crne Gore. Ono što ih posebno izdvaja jeste podatak, da su izuzetno
            uspješni u obje karate discipline - kate i borbe. Karatisti Iskre su
            osvajači medalja na najprestižnijim sportskim takmičenjima,
            uključujući Evropska prvenstva, Mediteranske igre, Balkanske
            prvenstva i mnoga druga.
          </p>
        </div>
      </div>

      <div className="column-section">
        <img src={heroGroupImage} className="middle-hero-img" />
        <p className="o_nama_column_paragraph ">
          U poslednjih nekoliko godina Klub bilježi izvanredne rezultate, po
          kojima se svrstava sigurno medju najboljim ne samo u zemlji, već i u
          regionu. Kao potvrda dobrog rada, u Klubu se danas mogu pohvaliti sa
          jednom Evropskom medaljom (Evropsko prvenstvo – Balša Vojinović, Prag
          – jun, 2022.godine). Pored Evropske vicešampionske titule, Klub je na
          Svjetskim ligama za mlade osvojio 9 medalja (Artifaj A., Vojinović B.,
          Petrić S.). Jedan su od rijetkih Klubova sa Balkana koji su uspjeli da
          dodju do odličja, kako u kategoriji kate, tako i u borbama.
        </p>
        <p className="o_nama_column_paragraph ">
          Na Balkanskim prvenstvima samo u poslednjih 4 godine, osvojili su
          preko 30 medalja. Po prvi put jedan njihov takmičar nastupio na
          Meditaranskom prvenstvu (Tunis – Maj, 2023.godina), na kojem je
          osvojio bronzanu medalju.
        </p>
      </div>

      <h2 className="hero-section-heading mt-32 mb-64">UPRAVNI ODBOR KLUBA</h2>

      <div className="treneri-div">
        <div className="uprava-card">
          <img
            alt="Vuk Zaric Uprava Kluba"
            className="uprava-img"
            src={upravaZaro}
          />

          <h3 className="trener-card-h">Vuk Žarić</h3>

          <p className="trener-card-p">Predsjednik</p>
        </div>

        <div className="uprava-card">
          <img
            alt="Aleksandar Bulatović Uprava Kluba"
            className="uprava-img"
            src={aleksandarBulatovic}
          />

          <h3 className="trener-card-h">Aleksandar Bulatović</h3>

          <p className="trener-card-p">član UO</p>
        </div>

        <div className="uprava-card">
          <img
            alt="Aleksandar Irić Uprava Kluba"
            className="uprava-img"
            src={aleksandarIric}
          />

          <h3 className="trener-card-h">Aleksandar Irić</h3>

          <p className="trener-card-p">član UO</p>
        </div>

        <div className="uprava-card">
          <img
            alt="Nenad Vojinović Uprava Kluba"
            className="uprava-img"
            src={upravaNesko}
          />

          <h3 className="trener-card-h">Nenad Vojinović</h3>

          <p className="trener-card-p">Sekretar</p>
        </div>

        <div className="uprava-card">
          <img
            alt="Marija Babić Uprava Kluba"
            className="uprava-img"
            src={upravaPsiho}
          />

          <h3 className="trener-card-h">Marija Babić</h3>

          <p className="trener-card-p">Psiholog</p>
        </div>
      </div>

      <h2 className="hero-section-heading mt-32 mb-64">TRENERI</h2>

      <div className="treneri-div">
        <div className="trener-card">
          <img className="trener-card-img" src={vukZaric} alt="Vuk Zaric" />

          <div className="trener-card-content">
            <h3 className="trener-card-h">Vuk Žarić</h3>
            <p className="trener-card-p">Trener</p>
            <p className="trener-card-p">Crni pojas, 3.DAN</p>
            <p className="trener-card-p">Magistar ekonomije</p>
            <p className="trener-card-p mt-12">
              “Uspjeh nije konačna destinacija, već putovanje. Nastojte biti
              bolji dan za danom.”
            </p>
          </div>
        </div>

        <div className="trener-card">
          <img
            className="trener-card-img"
            src={nenadVojinovic}
            alt="Vuk Zaric"
          />

          <div className="trener-card-content">
            <h3 className="trener-card-h">Nenad Vojinović</h3>
            <p className="trener-card-p">Trener</p>
            <p className="trener-card-p">Crni pojas, 3.DAN</p>
            <p className="trener-card-p">Magistrant bezbjednosti</p>
            <p className="trener-card-p mt-12">
              „Dvadeset godina od sada bićeš više razočaran stvarima koje nisi
              uradio, nego onima koje jesi.“
            </p>
          </div>
        </div>

        <div className="trener-card">
          <img
            className="trener-card-img"
            src={trenerPlaceholder}
            alt="Vuk Zaric"
          />

          <div className="trener-card-content">
            <h3 className="trener-card-h">Jugoslav Bukilić</h3>
            <p className="trener-card-p">Kata trener & Kondicioni trener</p>
            <p className="trener-card-p">Crni pojas, 4.DAN</p>
            <p className="trener-card-p">Dipl. pravnik</p>
            <p className="trener-card-p mt-12">
              &quot;Ne možete promijeniti vjetar, ali možete promijeniti smjer
              jedara.&quot; - Jimmy Dean
            </p>
          </div>
        </div>

        <div className="trener-card">
          <img
            className="trener-card-img"
            src={trenerPlaceholder}
            alt="Vuk Zaric"
          />

          <div className="trener-card-content">
            <h3 className="trener-card-h">Aleksandar Rajković</h3>
            <p className="trener-card-p">Rukovodilac karate školice</p>
            <p className="trener-card-p">Crni pojas, 4.DAN</p>
            <p className="trener-card-p">Dipl. Eng elektrotehnike</p>
            <p className="trener-card-p mt-12">
              &quot;Putovanje od hiljadu milja započinje jednim korakom.&quot; -
              Lao Tzu
            </p>
          </div>
        </div>

        <div className="trener-card">
          <img className="trener-card-img" src={markoDucic} alt="Vuk Zaric" />

          <div className="trener-card-content">
            <h3 className="trener-card-h">Marko Dučić</h3>
            <p className="trener-card-p">Kata trener</p>
            <p className="trener-card-p">Crni pojas, 2.DAN</p>
            <p className="trener-card-p">Pravnik</p>
            <p className="trener-card-p mt-12">
              Osmjer na licima djeteta vrijedi svaku kap znoja prolivenu na
              treningu. Prenositi znanje na djecu najveća je blagodet.
            </p>
          </div>
        </div>

        <div className="trener-card">
          <img
            className="trener-card-img"
            src={trenerPlaceholder}
            alt="Vuk Zaric"
          />

          <div className="trener-card-content">
            <h3 className="trener-card-h">Alen Vukčević</h3>
            <p className="trener-card-p">Trener</p>
            <p className="trener-card-p">Crni pojas, 2.DAN</p>
            <p className="trener-card-p">Profesor za fiz.kulturu</p>
            <p className="trener-card-p mt-12">
              &quot;Vaša sreća ne zavisi o tome što drugi ljudi rade; zavisi
              samo o tome što vi radite.&quot;
            </p>
          </div>
        </div>

        <div className="trener-card">
          <img
            className="trener-card-img"
            src={vladimirDavidovic}
            alt="Vuk Zaric"
          />

          <div className="trener-card-content">
            <h3 className="trener-card-h">Vladimir Davidović</h3>
            <p className="trener-card-p">Trener</p>
            <p className="trener-card-p">Crni pojas, 1.DAN</p>
            <p className="trener-card-p">SSS</p>
            <p className="trener-card-p mt-12">
              &quot;Neuspjeh je samo prilika za početak iznova, ovaj put
              pametnije.&quot; - Henry Ford
            </p>
          </div>
        </div>

        <div className="trener-card">
          <img
            className="trener-card-img"
            src={trenerPlaceholder}
            alt="Vuk Zaric"
          />

          <div className="trener-card-content">
            <h3 className="trener-card-h">Petar Tomović</h3>
            <p className="trener-card-p">Trener</p>
            <p className="trener-card-p">Crni pojas, 2.DAN</p>
            <p className="trener-card-p">Dipl ecc</p>
            <p className="trener-card-p mt-12">
              Malim koracima do velikih rezultata. Rad i upornost na kraju dana
              su uvjek pobjednici. Budi i ti jedan od njih.
            </p>
          </div>
        </div>

        <div className="trener-card">
          <img
            className="trener-card-img"
            src={milosPopovic}
            alt="Milos Popovic"
          />

          <div className="trener-card-content">
            <h3 className="trener-card-h">Miloš Popović</h3>
            <p className="trener-card-p">Trener</p>
            <p className="trener-card-p">Crni pojas, 1.DAN</p>
            <p className="trener-card-p">Apsolvent prava</p>
            <p className="trener-card-p mt-12">
              "Uspjeh nije konačan, neuspjeh nije fatalan: hrabrost da nastaviš
              je ono što se računa." - Winston Churchill
            </p>
          </div>
        </div>
      </div>

      <h2 className="hero-section-heading mb-32">AKTIVNOSTI KLUBA</h2>

      <div className="two-things-div">
        <div className="one-side-thing-div">
          <h3 className="hero-paragraph-subheading">
            Društveno odgovorno ponašanje
          </h3>
          <p className="hero-paragraph">
            Karatisti Iskre su prepoznali značaj očuvanja planete, te su svoj
            doprinos dali u vidu doniranja sadnica OŠ “Pavle Rovinski” u
            septembru mjesecu. Naši šampioni se ne bore samo za medalje, već i
            za cijelu planetu. U to ime karatisti Iskre su napravili donaciju
            osnovnoj školi u vidu prelijepih stabala indijskog jorgovana. Sport
            i priroda uvjek idu zajedno, te je akcenat u Klubu stavljen na
            promociji zdravih životnih vrijednosti.
          </p>
        </div>

        <div className="one-side-thing-div">
          <img
            src={drustveno}
            alt="Karate Aktivnosti Drustveno odgovorno ponasanje"
            className="one-side-img aktivnost-img"
          />
        </div>
      </div>

      <div className="two-things-div">
        <div className="one-side-thing-div">
          <h3 className="hero-paragraph-subheading">Humanitarni rad</h3>
          <p className="hero-paragraph">
            Naši takmičari su se pokazali kao ljudi dobre volje. Dobrovoljnim
            prilozima pomogli su u nevolji porodici Savović iz Danilovgrada i
            porodici Pejović iz Nikšića;
          </p>
        </div>

        <div className="one-side-thing-div">
          <img
            src={humanitarni}
            alt="Karate Aktivnosti Humanitarni rad"
            className="one-side-img aktivnost-img"
          />
        </div>
      </div>

      <div className="two-things-div">
        <div className="one-side-thing-div">
          <h3 className="hero-paragraph-subheading">Izleti</h3>
          <p className="hero-paragraph">
            Sport i priroda su dvije stvari koje uvjek idu zajedno. Trudili smo
            se da pored boravka u netaknutoj prirodu, djeca uživaju u druženju,
            ali i u edukaciji koja se odnosi na naše istorijsko i kulturno
            nasledje. Shodno tome, ove godine smo organizovali izlete na
            Lovćenu, Biogradskoj gori i na brojnim drugim lokalitetima.
          </p>
        </div>

        <div className="one-side-thing-div">
          <img
            src={izleti}
            alt="Karate Akrivnosti Izleti"
            className="one-side-img aktivnost-img aktivnost-img"
          />
        </div>
      </div>

      <h2 className="hero-section-heading mt-32 mb-64">KLUB PRIJATELJA</h2>

      <div className="two-things-div">
        <div className="one-side-thing-div">
          <img
            src={klubPrijatelja}
            alt="Karate Klub Iskra Istorijat"
            className="one-side-img"
          />
        </div>

        <div className="one-side-thing-div">
          <p className="hero-paragraph">
            Klub prijatelja KK “ISKRA”je ideja koja ima za cilj da okupi
            partnere, prijatelje, koji će se susretati dva puta godišnje kada će
            stručni tim Kluba prezentovati postignute rezultate, kako u
            sportskom, tako i edukativnom smislu koji ostvaruju članovi “Iskre”
            sa jasnim predlozima i rešenjima za njihovim unapredjenjem. Ovo je
            istovremeno poziv svim ljudima dobre volje da postanu članovi Kluba
            prijatelja kako bi smo zajednički, olakšali našoj djeci ostvarenje
            svojih snova u vidu sportskih rezultata. Na taj način, doprinosimo
            ne samo pojedincima, već i cjelokupnom društvu.
          </p>
          <div onClick={downloadDocument} className="button">
            Pozivnica
          </div>
        </div>
      </div>

      <div className="rezultati-div animate__animated animate__fadeInUp">
        <div className="rezultati-content">
          <h2 className="rezultati-h">ČLANOVI KLUBA PRIJATELJA:</h2>

          <p className="rezultati-p">
            1. Nina Drakić , Predsjednica privredne komore Crne Gore
            –PREDSJEDNICA KLUBA PRIJATELJA
          </p>
          <p className="rezultati-p">
            2. Aleksandar Aco Bulatović – Komercijalni director DMD Delta, bivši
            karatista – član UO kluba;
          </p>
          <p className="rezultati-p">
            3. Aleksandar Irić – izv.direktor “Stadion logistics”, bivši
            karatista – član UO kluba;
          </p>
          <p className="rezultati-p">
            4. Aleksandar Babić – direktor filijale “Hipotekarne banke”
            Danilovgrad;
          </p>
          <p className="rezultati-p">
            5. Baćko Babić – izv. direktor ISEM agencije;
          </p>
          <p className="rezultati-p">
            6. Dino Arifaj – izv.direktor “PG mobile”;
          </p>
          <p className="rezultati-p">
            7. Nenad Prelević – izv.direktor “LIN company”;
          </p>
          <p className="rezultati-p">
            8. Damjan Bakić – komercijalni director “Novi Volvox”;
          </p>
          <p className="rezultati-p">
            9. Vlado Lalatović – izv.direktor “Lalatović tours”;
          </p>
          <p className="rezultati-p">
            10. Arko Pepić – izv.direktor “Gold travel”;
          </p>
          <p className="rezultati-p">
            11. Dragan Stojanović – izv.direktor “Cermat-a”;
          </p>
          <p className="rezultati-p">
            12. Goran Dragaš – šef korporativnog odeljenja “Sava osiguranje”;
          </p>
          <p className="rezultati-p">13. Emil Ličina – novinar Mina news…</p>
        </div>
      </div>

      <Kontakt />

      <Footer />
    </div>
  );
};

export default OKlubu;
