import React, { useState, useEffect } from "react";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import Header from "../../components/header";
import Footer from "../../components/footer";
import blogArrow from "../../assets/blog_arrow.png";
import { Link } from "react-router-dom";
import "./style.css";
import reviewsSearchIcon from "../../assets/reviews_input_search.png";

const Blog = () => {
  const [novosti, setNovosti] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredNovosti, setFilteredNovosti] = useState([]);

  const formatDateTime = (dateString) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    return new Date(dateString)
      .toLocaleString("en-US", options)
      .replace(",", "");
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase().trim());

    const filteredNovosti = novosti.filter((novost) => {
      return (
        novost.naslov.toLowerCase().includes(searchQuery) ||
        novost.seoNaslov.toLowerCase().includes(searchQuery) ||
        novost.seoOpis.toLowerCase().includes(searchQuery) ||
        novost.slug.toLowerCase().includes(searchQuery)
      );
    });

    setFilteredNovosti(filteredNovosti);
  };

  useEffect(() => {
    const getNovosti = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/get-all`
        );

        setNovosti(response.data);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };

    getNovosti();
  }, []);

  useEffect(() => {
    setFilteredNovosti(novosti);
  }, [novosti]);

  return (
    <div className="page-container">
      <Header />

      <div className="blog-page-content mt-100">
        <div className="blog-heading-div">
          <h2 className="hero-section-heading">NOVOSTI</h2>
          <div className="search-input-div">
            <img className="search-img" src={reviewsSearchIcon} />

            <input
              value={searchQuery}
              onChange={handleSearch}
              className="search-input"
              name="search"
              placeholder="Pretraži"
            />
          </div>
        </div>

        <div className="blogs-div">
          {loading ? (
            <div className="loading-div">
              <ClipLoader size={40} color={"#fff"} />
            </div>
          ) : (
            filteredNovosti?.map((novost) => {
              return (
                <div
                  key={novost.id}
                  className="animate__animated animate__fadeInUp novost-card"
                >
                  <img className="blog-card-img" src={novost?.image?.url} />
                  <p className="blog-card-date">
                    {formatDateTime(novost?.timestamp)}
                  </p>
                  <h3 className="blog-card-heading">{novost.seoNaslov}</h3>
                  <p className="blog-card-text">{novost.seoOpis}</p>
                  <Link
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                    to={`/novosti/${novost.slug}`}
                  >
                    <div className="blog-card-btn-div">
                      <p className="blog-card-btn-p">Pročitaj više</p>
                      <img className="blog-card-btn-img" src={blogArrow} />
                    </div>
                  </Link>
                </div>
              );
            })
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Blog;
