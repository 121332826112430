import React from "react";
import Header from "../../components/header";
import Kontakt from "../../components/kontakt";
import Footer from "../../components/footer";
import heroImage from "../../assets/karate_skola_hero.png";
import { Link } from "react-router-dom";

const KarateSkola = () => {
  return (
    <div className="page-container">
      <Header />

      <div className="hero-section">
        <div className="hero-section-right-div animate__animated animate__fadeInLeft">
          <h1 className="hero-section-heading">KARATE SKOLA</h1>
          <p className="hero-paragraph">
            U karate školi osim daljeg razvijanja motoričkih sposobnosti,
            poseban naglasak se stavlja na učenje osnova karate vještine.
            Stavlja se veći akcenat na praktičnim znanjima, te se djeca
            usmjeravaju shodno preferencijama i afinitetima na kate, odnosno
            kumite (borbe). Upravo, veliki trenažnih grupa, koje su napravljene
            prema godištu, afinitetima, mogućnostima svakog djeteta –
            pretstavlja jednu od glavnih preduslova za razvoj svakog djeteta.
          </p>
          <Link
            className="link"
            to={"/kontakt"}
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            <div className="button">Kontakt</div>
          </Link>
        </div>

        <div className="hero-section-left-div animate__animated animate__fadeInRight">
          <img
            alt="Karate Klub Iskra Karate skola"
            className="hero-img"
            src={heroImage}
          />
        </div>
      </div>

      <Kontakt />

      <Footer />
    </div>
  );
};

export default KarateSkola;
