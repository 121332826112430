import React, { useState } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import Hamburger from "hamburger-react";

import infoEmailIcon from "../../assets/info_email.png";
import infoCallIcon from "../../assets/info_call.png";
import instagram from "../../assets/header_instagram.png";
import facebook from "../../assets/header_facebook.png";
import adminIcon from "../../assets/admin_icon.png";
import logo from "../../assets/header_logo.png";
import HeadTags from "../../HeadTags";

const Header = ({ title, metaDescription, shareImage }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="header-container">
      <div className="info-line">
        <div className="info-content">
          <div className="info-property-div">
            <img
              alt="Karate Klub Iskra Broj Telefona"
              src={infoCallIcon}
              className="info-line-icon"
            />
            <p>+382 67 518 080</p>
          </div>
          <div className="info-property-div">
            <img
              alt="Karate Klub Iskra Broj Telefona"
              src={infoCallIcon}
              className="info-line-icon"
            />
            <p>+382 67 285 464</p>
          </div>
          <div className="info-property-div">
            <img
              alt="Karate Klub Iskra Email Adresa"
              src={infoEmailIcon}
              className="info-line-icon"
            />
            <p>karateiskra@gmail.com</p>
          </div>
        </div>
      </div>
      <div className="header-div">
        <div className="header-help-div">
          <a target="_blank" href="https://www.instagram.com/karateklubiskra/">
            <img
              alt="Karate Klub Iskra Instagram"
              className="header-social-icon"
              src={instagram}
            />
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/p/Karate-klub-ISKRA-100063772861426/"
          >
            <img
              alt="Karate Klub Iskra Facebook"
              className="header-social-icon header-facebook-icon"
              src={facebook}
            />
          </a>
        </div>

        <div className="links-div">
          <Link
            onClick={() => {
              window.scroll(0, 0);
            }}
            to={"/"}
            className="header-link"
          >
            Početna
          </Link>
          <Link
            onClick={() => {
              window.scroll(0, 0);
            }}
            to={"/o-klubu"}
            className="header-link"
          >
            O klubu
          </Link>
          <Link
            onClick={() => {
              window.scroll(0, 0);
            }}
            to={"/programi"}
            className="header-link"
          >
            Programi
          </Link>
          <img
            alt="Karate Klub Iskra Logo"
            className="header-logo"
            src={logo}
          />
          <Link
            onClick={() => {
              window.scroll(0, 0);
            }}
            to={"/rezultati"}
            className="header-link"
          >
            Rezultati
          </Link>
          <Link
            onClick={() => {
              window.scroll(0, 0);
            }}
            to={"/novosti"}
            className="header-link"
          >
            Novosti
          </Link>
          <Link
            onClick={() => {
              window.scroll(0, 0);
            }}
            to={"/kontakt"}
            className="header-link"
          >
            Kontakt
          </Link>
        </div>

        <a
          target="_blank"
          href="https://admin.karateiskra.me"
          className="header-admin-div"
        >
          <img className="header-admin-icon" src={adminIcon} />
        </a>

        <div className="header-hamburger-div">
          <Hamburger
            toggled={menuOpen}
            toggle={() => {
              setMenuOpen((prevState) => !prevState);
            }}
            size={25}
            color="#212121"
            duration={0.2}
          />
        </div>
      </div>
      <div
        className={`header-mobile-div ${menuOpen && "header-mobile-div-open"}`}
      >
        <Link
          onClick={() => {
            window.scroll(0, 0);
          }}
          to={"/"}
          className="header-mobile-link"
        >
          Početna
        </Link>
        <Link
          onClick={() => {
            window.scroll(0, 0);
          }}
          to={"/o-klubu"}
          className="header-mobile-link"
        >
          O klubu
        </Link>
        <Link
          onClick={() => {
            window.scroll(0, 0);
          }}
          to={"/programi"}
          className="header-mobile-link"
        >
          Programi
        </Link>
        <Link
          onClick={() => {
            window.scroll(0, 0);
          }}
          to={"/rezultati"}
          className="header-mobile-link"
        >
          Rezultati
        </Link>
        <Link
          onClick={() => {
            window.scroll(0, 0);
          }}
          to={"/novosti"}
          className="header-mobile-link"
        >
          Novosti
        </Link>
        <Link
          onClick={() => {
            window.scroll(0, 0);
          }}
          to={"/kontakt"}
          className="header-mobile-link"
        >
          Kontakt
        </Link>
        <a
          target="_blank"
          href="https://admin.karateiskra.me"
          className="header-mobile-link"
        >
          Admin
        </a>
      </div>
    </div>
  );
};

export default Header;
