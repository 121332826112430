import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router";
import { HelmetProvider } from "react-helmet-async";

import Pocetna from "./pages/pocetna";
import KontaktPage from "./pages/kontakt";
import Rezultati from "./pages/rezultati";
import Programi from "./pages/programi";
import Novosti from "./pages/novosti";
import OKlubu from "./pages/oKlubu";
import KarateSkola from "./pages/karateSkola";
import TakmicarskiKarate from "./pages/taklicarskiKarate";
import KarateVrtic from "./pages/karateVrtic";
import Novost from "./pages/novost";

const App = () => {
  return (
    <div>
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Pocetna />} />
            <Route path="/o-klubu" element={<OKlubu />} />
            <Route path="/programi" element={<Programi />} />
            <Route path="/programi/karate-vrtic" element={<KarateVrtic />} />
            <Route path="/programi/karate-skola" element={<KarateSkola />} />
            <Route
              path="/programi/takmicarski-karate"
              element={<TakmicarskiKarate />}
            />
            <Route path="/rezultati" element={<Rezultati />} />
            <Route path="/novosti" element={<Novosti />} />
            <Route path="/novosti/:novostSlug" element={<Novost />} />

            <Route path="/kontakt" element={<KontaktPage />} />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
};

export default App;
